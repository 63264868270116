<template>
  <div class="assessments-container">
    <MainHeader
      :description="$t('home.header.description')"
      :module="$t('home.header.title')"
      :page-title="$t('home.header.subtitle')"
    >
      <template #default>
        <s-button
          v-for="tab in tabs"
          :key="tab.name"
          class="assessments-container__tab"
          :size="tabSize"
          :to="tab.route"
          variation="tertiary"
        >
          {{ tab.name }}
        </s-button>
      </template>
      <template #header>
        <div
          class="csat-button"
          @click="handleNPS()"
        >
          <SasIcon type="star" />
          <div>Avalie sua experiência</div>
        </div>
      </template>
    </MainHeader>
    <router-view />
  </div>
</template>

<script>
import moment from 'moment'
import typeform from '@/services/typeform'

import MainHeader from '@/components/MainHeader'

import accountApi from '@/api/account'

export default {
  name: 'Home',
  components: {
    MainHeader,
  },
  computed: {
    tabs() {
      return [
        {
          name: this.$t('home.header.tab.sas'),
          route: { name: 'assessments' },
        },
      ]
    },
    tabSize() {
      return this.$mq.includes('x_small') ? 'small' : 'medium'
    },
    headerImage() {
      const images = require && require.context('../assets/', false, /\.svg$/)

      return images ? images('./student.svg') : ''
    },
  },
  methods: {
    async handleNPS() {
      const formId = 's7uwGkzf'
      const { data } = await accountApi.getUser()
      const currentUrl = window.location.href
      const currentDate = moment().format('YYYY-MM-DD')
      const hiddenFields = {
        account_id: data.id,
        account_name: data.name,
        school_id: data.school.id,
        school_name: data.school.name,
        date: currentDate,
        url: currentUrl,
      }

      const typeformPopup = typeform.createPopup({
        formId,
        hiddenFields,
        options: {
          hideScrollbars: true,
          mode: 'popup',
          size: 75,
        },
      })

      typeformPopup.open()
    },
  },
}
</script>

<style lang="sass" scoped>
.assessments-container

  &__tab
    color: $color-white
    margin-bottom: $size-xxs

    &.--active
      color: $color-white

      &.router-link-active::after
        position: absolute
        content: ''
        height: $size-xxs
        width: 100%
        bottom: - $size-xxs
        border-radius: $border-radius-l
        background: $color-white

    & ~ &
      margin-left: $size-m

.csat-button
  color: $color_white
  display: flex
  flex-direction: row
  &:hover
     cursor: pointer
</style>
